/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Dosis_c88107';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/10a3352fc71482ec-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Dosis_c88107';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9b8d9bd9da1c904b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Dosis_c88107';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c6e04b5f0940e652-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Dosis_c88107';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10a3352fc71482ec-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Dosis_c88107';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9b8d9bd9da1c904b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Dosis_c88107';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c6e04b5f0940e652-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Dosis_Fallback_c88107';src: local("Arial");ascent-override: 121.44%;descent-override: 28.03%;line-gap-override: 0.00%;size-adjust: 84.57%
}.__className_c88107 {font-family: '__Dosis_c88107', '__Dosis_Fallback_c88107';font-style: normal
}

/* ============================================================
   Navbar 
   ============================================================ */

.page-header {
  background: rgb(var(--content-bg-rgb));
  display: flex;
  flex-direction: column;
  padding: 0.1em 0.3em;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.1em;
}

.title-search-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header-logo-link  {
  text-decoration: none;
}

.header-logo {
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0.4rem;
  margin-top: 0.2rem;
  background: linear-gradient(135deg,
    rgb(192, 0, 192), rgb(32, 32, 255), rgb(0, 160, 32));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-footer {
  background: rgb(var(--content-bg-rgb));
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Put at bottom of page */
  margin-top: auto;

  padding: 0.5em;
}

.buttonbar ul.buttonlist {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

@media (max-width: 600px) {
  .buttonbar ul.buttonlist {
    gap: 0.3rem;
  }
}

/* Responsive navigation for mobile */
/* This actually isn't a great layout, but keeping it around as an example to play with */
@media (max-width: 300px) {
  .buttonbar ul.buttonlist {
    flex-direction: column; /* Stacks the links vertically */
    gap: 0; /* Removes the gap for a stacked layout */
  }

  .buttonbar ul.buttonlist li {
    padding: 1rem; /* Adds more padding for touch targets */
  }
}


.buttonbar ul.buttonlist li {
  margin: 0;
}

.buttonbar ul.buttonlist a {
  text-decoration: none;
  color: rgb(var(--foreground-rgb));
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  /* background: rgb(var(--button-bg-rgb)); */
  transition: background 0.3s, box-shadow 0.3s, padding 0.3s;
  white-space: nowrap;
  display: inline-block;
}

@media (min-width: 768px) {
  .buttonbar ul.buttonlist {
    gap: 1rem;
  }

  .buttonbar ul.buttonlist a {
    padding: 0.4rem 0.8rem;
  }
}

.buttonbar ul.buttonlist a:hover,
.buttonbar ul.buttonlist a:focus {
  background: rgb(var(--button-hover-bg-rgb));
}

.buttonbar ul.buttonlist a.active {
  background: rgb(var(--button-active-bg-rgb));
  box-shadow: 0 0 0 2px rgb(var(--button-active-border-rgb));
}

.buttonbar ul.buttonlist li {
  position: relative;
  margin-top: 2px;
}

.header-icon-button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.2em;
}

.popup-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgb(var(--content-bg-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.4rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  min-width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-menu link {
  padding: 0.5rem 1rem;
}

.popup-menu a {
  padding: 0.5rem 1rem;
  margin: 0.4rem;
  text-decoration: none;
  color: rgb(var(--foreground-rgb));
  white-space: nowrap;
}

.popup-menu a:hover {
  background-color: rgba(var(--foreground-rgb), 0.1);
}

/* ============================================================
   Topic selector
   ============================================================ */

.topic-selector {
  width: 90%;
  max-width: 500px;
  margin: 0.8em;
}

.topic-container {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  margin-top: 0.0em;
  margin-bottom: 0.3em;
  height: 2.5rem; /* Set a fixed height */
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 1.0em;
  color: rgb(var(--foreground-rgb));
  padding: 0;
}

.topic-selected {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.topic-selector-container {
  flex-grow: 1;
  max-width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.topic-selector-container > div {
  width: 100%;
}

.topic-selector-container input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.5rem;
  background-color: rgb(var(--textbox-bg-rgb));
  color: rgb(var(--foreground-rgb));
}

.topic-selector-container input:focus {
  outline: none;
  border-color: rgba(var(--foreground-rgb), 0.4);
}

.topic-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgb(var(--background-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.5rem;
  margin-top: 0.25rem;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.omnisearch-result.selected {
  background-color: rgba(var(--foreground-rgb), 0.1);
}


