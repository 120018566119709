/* ============================================================
   Navbar 
   ============================================================ */

.page-header {
  background: rgb(var(--content-bg-rgb));
  display: flex;
  flex-direction: column;
  padding: 0.1em 0.3em;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.1em;
}

.title-search-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header-logo-link  {
  text-decoration: none;
}

.header-logo {
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0.4rem;
  margin-top: 0.2rem;
  background: linear-gradient(135deg,
    rgb(192, 0, 192), rgb(32, 32, 255), rgb(0, 160, 32));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-footer {
  background: rgb(var(--content-bg-rgb));
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Put at bottom of page */
  margin-top: auto;

  padding: 0.5em;
}

.buttonbar ul.buttonlist {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

@media (max-width: 600px) {
  .buttonbar ul.buttonlist {
    gap: 0.3rem;
  }
}

/* Responsive navigation for mobile */
/* This actually isn't a great layout, but keeping it around as an example to play with */
@media (max-width: 300px) {
  .buttonbar ul.buttonlist {
    flex-direction: column; /* Stacks the links vertically */
    gap: 0; /* Removes the gap for a stacked layout */
  }

  .buttonbar ul.buttonlist li {
    padding: 1rem; /* Adds more padding for touch targets */
  }
}


.buttonbar ul.buttonlist li {
  margin: 0;
}

.buttonbar ul.buttonlist a {
  text-decoration: none;
  color: rgb(var(--foreground-rgb));
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  /* background: rgb(var(--button-bg-rgb)); */
  transition: background 0.3s, box-shadow 0.3s, padding 0.3s;
  white-space: nowrap;
  display: inline-block;
}

@media (min-width: 768px) {
  .buttonbar ul.buttonlist {
    gap: 1rem;
  }

  .buttonbar ul.buttonlist a {
    padding: 0.4rem 0.8rem;
  }
}

.buttonbar ul.buttonlist a:hover,
.buttonbar ul.buttonlist a:focus {
  background: rgb(var(--button-hover-bg-rgb));
}

.buttonbar ul.buttonlist a.active {
  background: rgb(var(--button-active-bg-rgb));
  box-shadow: 0 0 0 2px rgb(var(--button-active-border-rgb));
}

.buttonbar ul.buttonlist li {
  position: relative;
  margin-top: 2px;
}

.header-icon-button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.2em;
}

.popup-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgb(var(--content-bg-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.4rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  min-width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-menu link {
  padding: 0.5rem 1rem;
}

.popup-menu a {
  padding: 0.5rem 1rem;
  margin: 0.4rem;
  text-decoration: none;
  color: rgb(var(--foreground-rgb));
  white-space: nowrap;
}

.popup-menu a:hover {
  background-color: rgba(var(--foreground-rgb), 0.1);
}
