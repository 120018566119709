/* ============================================================
   Topic selector
   ============================================================ */

.topic-selector {
  width: 90%;
  max-width: 500px;
  margin: 0.8em;
}

.topic-container {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  margin-top: 0em;
  margin-bottom: 0.3em;
  height: 2.5rem; /* Set a fixed height */
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 1em;
  color: rgb(var(--foreground-rgb));
  padding: 0;
}

.topic-selected {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.topic-selector-container {
  flex-grow: 1;
  max-width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.topic-selector-container > div {
  width: 100%;
}

.topic-selector-container input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.5rem;
  background-color: rgb(var(--textbox-bg-rgb));
  color: rgb(var(--foreground-rgb));
}

.topic-selector-container input:focus {
  outline: none;
  border-color: rgba(var(--foreground-rgb), 0.4);
}

.topic-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgb(var(--background-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.5rem;
  margin-top: 0.25rem;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.omnisearch-result.selected {
  background-color: rgba(var(--foreground-rgb), 0.1);
}
